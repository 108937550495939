import logo from "./logo.png";
import smallLogo from "./logo.ico";
import "./App.css";

function App() {
  return (
    <>
      <style>
        {`
              @media (max-width: 768px) {
                .responsive-grid {
                  grid-template-columns: 1fr !important;
                }
              }
            `}
      </style>
      <nav className="navbar">
        <a href="/">
          <img src={logo} className="navbar-logo" alt="Logo de Umuka" />
        </a>
        <li>
          <a
            href="https://www.instagram.com/umuka.co/"
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=573174526832"
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            WhatsApp
          </a>
        </li>
      </nav>
      <header className="app-header" style={{ padding: "70px 30px" }}>
        <h1
          style={{ color: "#546d5e", fontSize: "3rem", margin: "0" }}
          className="welcome-text"
        >
          Umuka: Transformando el Futuro
        </h1>
        <p
          className="description-text"
          style={{ fontSize: "1rem", marginBottom: "5rem" }}
        >
          En umuka transformamos residuos en papelería artesanal sostenible,
          fomentando un consumo consciente y dejando una huella positiva en el
          planeta.
        </p>
        <p
          className="description-text"
          style={{ fontSize: "1rem", marginBottom: "3rem" }}
        >
          Fusionamos tradición e innovación para crear productos que inspiran
          conciencia ambiental y transforman comunidades.
        </p>
      </header>
      <main>
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            maxWidth: "1200px",
            margin: "auto",
            padding: "20px",
            backgroundColor: "#f5f5f5",
            color: "#434343",
          }}
        >
          {/* Misión y Visión */}
          <section
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              marginTop: "30px",
            }}
            className="responsive-grid"
          >
            <div
              style={{
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
            >
              <h2 style={{ color: "#546d5e" }}>Misión</h2>
              <p>
                Transformamos residuos en papelería artesanal sostenible,
                fomentando un consumo consciente y dejando una huella positiva.
              </p>
            </div>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
            >
              <h2 style={{ color: "#546d5e" }}>Visión</h2>
              <p>
                Ser líderes en la creación de papelería artesanal sostenible,
                fusionando tradición e innovación para inspirar conciencia
                ambiental.
              </p>
            </div>
          </section>

          {/* Quiénes Somos */}
          <section
            style={{
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <h2 style={{ color: "#546d5e" }}>Quiénes somos</h2>
            <p>
              En Umuka transformamos residuos en papelería artesanal sostenible,
              fusionando tradición e innovación para inspirar conciencia
              ambiental.
            </p>
            <p>
              <strong>Manifiesto:</strong> Tejedores de historias, guardianes de
              la memoria y preservadores de vida.
            </p>
          </section>

          {/* Valores Corporativos */}
          <section style={{ marginTop: "30px" }}>
            <h2 style={{ color: "#546d5e" }}>Valores Corporativos</h2>
            <ul style={{ listStyle: "none", padding: "0" }}>
              <li
                style={{
                  padding: "10px",
                  backgroundColor: "#e8f5e9",
                  margin: "5px 0",
                  borderRadius: "5px",
                }}
              >
                🌿 Responsabilidad Ambiental
              </li>
              <li
                style={{
                  padding: "10px",
                  backgroundColor: "#e3f2fd",
                  margin: "5px 0",
                  borderRadius: "5px",
                }}
              >
                💙 Empatía Social
              </li>
              <li
                style={{
                  padding: "10px",
                  backgroundColor: "#fff3e0",
                  margin: "5px 0",
                  borderRadius: "5px",
                }}
              >
                🤝 Colaboración y Trabajo en Equipo
              </li>
            </ul>
          </section>

          {/* Beneficios */}
          <section style={{ marginTop: "30px" }}>
            <h2 style={{ color: "#546d5e" }}>Beneficios para tu empresa</h2>
            <ul style={{ listStyle: "none", padding: "0" }}>
              <li>📌 Resalta tu marca sin generar residuos.</li>
              <li>📌 Crea una conexión emocional con tus clientes.</li>
              <li>📌 Un merchandising con propósito y sostenibilidad.</li>
            </ul>
          </section>

          {/* Productos */}
          <section style={{ marginTop: "30px" }}>
            <h2 style={{ color: "#546d5e" }}>¿Qué hacemos?</h2>
            <p>Transformamos en una experiencia sostenible y memorable.</p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
              }}
              className="responsive-grid"
            >
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <h3 style={{ color: "#546d5e" }}>Merchandising ecológico</h3>
                <p>
                  Libretas germinables, invitaciones, separadores, tarjetas,
                  calendarios, tarjetas de presentación, manillas, credenciales
                  para eventos, kit plantables, etiquetas.
                </p>
              </div>
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <h3 style={{ color: "#546d5e" }}>
                  Productos que cuentan historias
                </h3>
                <p>
                  Juegos de cartas y recetarios que conectan generaciones y
                  refuerzan valores familiares.
                </p>
              </div>
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                }}
              >
                <h3 style={{ color: "#546d5e" }}>
                  Talleres de papel artesanal
                </h3>
                <p>
                  Experiencias creativas para empresas y eventos, donde el papel
                  cobra vida desde la fibra hasta el producto final.
                </p>
              </div>
            </div>
          </section>

          {/* Contacto */}
          <section
            style={{
              marginTop: "30px",
              textAlign: "center",
              padding: "20px",
              backgroundColor: "#546d5e",
              color: "white",
              borderRadius: "10px",
            }}
          >
            <h2>💡 Tu marca merece destacar de forma sostenible.</h2>
            <p>Hablemos de cómo podemos crear juntos impacto positivo.</p>
            <p>📩 Contáctanos:</p>
            <p>📧 hola@umuka.co</p>
          </section>
        </div>
      </main>
      <footer
        className="app-footer"
        style={{
          width: "100vw",
          height: "70px",
          borderTop: "1px solid #e9e7e7",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <p className="footer-text">
          &copy; {new Date().getFullYear()} Umuka. All rights reserved.
        </p>
      </footer>
    </>
  );
}

export default App;
